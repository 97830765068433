import { Controller } from "stimulus";

const passwordType = "password";
const textType = "text";

export default class extends Controller {
  connect() {
    this._setInputElement();
    this._addClassNameToContainer();
  }

  // Actions
  //
  toggle(event) {
    const inputElement = this.inputElement;
    const className = "password-show";

    if (!inputElement) {
      return;
    }

    if (inputElement.type === passwordType) {
      inputElement.type = textType;
      this.element.classList.add(className);
    } else {
      inputElement.type = passwordType;
      this.element.classList.remove(className);
    }
  }
  //
  // End actions

  get nearestPasswordInput() {
    let element = this.element.previousElementSibling;

    while (element) {
      if (element.type === passwordType) {
        break;
      }
      element = element.previousElementSibling;
    }

    return element;
  }

  _setInputElement() {
    this.inputElement = this.nearestPasswordInput;
  }

  _addClassNameToContainer() {
    this.element.parentElement.classList.add("password-viewer--container");
  }
}
