import { Controller } from "stimulus";
import * as _ from "lodash";

export default class extends Controller {
  static targets = [
    "datepicker",
    "submit",
    "nameField",
    "emailField",
    "passwordField",
    "termsCheckbox",
  ];

  connect() {
    this._initializeDatepicker();
    this._showFlash();
    this.enableButton();
  }

  // Actions
  //
  enableButton() {
    this.submitTarget.disabled =
      this._isBlank(this.nameFieldTarget.value) ||
      this._isBlank(this.emailFieldTarget.value) ||
      this._isBlank(this.passwordFieldTarget.value) ||
      !this.termsCheckboxTarget.checked;
  }
  //
  // End actions

  _initializeDatepicker() {
    const picker = datepicker(this.datepickerTarget, {
      customDays: ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"],
      customMonths: [
        "Januari",
        "Februari",
        "Maart",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Augustus",
        "September",
        "Oktober",
        "November",
        "December",
      ],
      overlayButton: "Opslaan",
      overlayPlaceholder: "Vul jaartal in",
      formatter: function (el, date) {
        const options = { year: "numeric", month: "long", day: "numeric" };
        el.value = date.toLocaleDateString("nl-NL", options);
      },
      dateSelected: new Date(),
    });
  }

  _showFlash() {
    const modalNotice = document.getElementById("modal-message");
    const flash = document.getElementById("flash");

    if (modalNotice) modalNotice.style.display = "none";

    if (flash) {
      if (modalNotice) modalNotice.style.display = "flex";
      setTimeout(() => {
        if (modalNotice) modalNotice.style.display = "none";
      }, 4000);
    }
  }

  _isBlank(value) {
    return _.isEmpty(_.trim(value));
  }
}
