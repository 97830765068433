import { Controller } from "stimulus";
import _ from "lodash";

// This controller checks if an input element is not empty.
//
// The following markup is expected:
//
// <div data-controller="validators--presence"
//      data-validators--presence-message="...">
//   <input data-target="validators--presence.input"
//          data-action="input->validators--presence#validate">
//   <span data-target="validators--presence.message"></span>
// </div>
export default class extends Controller {
  static targets = ["input", "message"];

  // Actions
  //
  validate() {
    const errorClassName = "container-control--error";
    const element = this.element;
    const messageTarget = this.messageTarget;

    if (this._isBlank(this.inputTarget.value)) {
      element.classList.add(errorClassName);
      messageTarget.innerText = this.data.get("message");
    } else {
      element.classList.remove(errorClassName);
      messageTarget.innerText = "";
    }
  }
  //
  // End actions

  _isBlank(value) {
    return _.isEmpty(_.trim(value));
  }
}
