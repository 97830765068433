import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["emailContainer", "passwordContainer"];

  connect() {
    this._showFlash();
  }

  _showFlash() {
    const flash = document.getElementById("flash");
    const errorClassName = this.data.get("error-class");

    if (flash && flash.className.includes("flash_warning")) {
      this.emailContainerTarget.classList.add(errorClassName);
      this.passwordContainerTarget.classList.add(errorClassName);
    }
  }
}
